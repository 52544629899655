<template>
    <v-container fluid>
        <v-form @submit.prevent="getMoving()">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-subheader class="headline">{{ $t('menu_moving') }}</v-subheader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0"  cols="12" sm="6">
                                <v-menu v-model="dateMenuFrom" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateFrom" name="date_from" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateFrom" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_from')"
                                                          prepend-icon="mdi-calendar" color="primary" readonly v-on="on"
                                                          clearable
                                                          @click:clear="dateFrom = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateFrom"
                                                   :max="dateTo ? $moment(dateTo).format('YYYY-MM-DD') : $moment().format('YYYY-MM-DD')"
                                                   :locale="lang"
                                                   @change="dateMenuFrom = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="py-0"  cols="12" sm="6">
                                <v-menu v-model="dateMenuTo" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="dateTo" name="date_to" rules="date_format"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="dateTo" :error-messages="errors"
                                                          :disabled="loading" :label="$t('date_to')"
                                                          prepend-icon="mdi-calendar" color="primary" readonly v-on="on"
                                                          clearable
                                                          @click:clear="dateTo = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-date-picker ref="picker" v-model="dateTo"
                                                   :min="dateFrom ? $moment(dateFrom).format('YYYY-MM-DD') : $moment().subtract(100, 'y').format('YYYY-MM-DD')"
                                                   :max="$moment().format('YYYY-MM-DD')" :locale="lang"
                                                   @change="dateMenuTo = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <v-row>

                            <v-col cols="12" sm="12" md="12"
                                   class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text class="px-0">
                <v-data-table :headers="filteredHeaders" :items="movingItems" :options.sync="options"
                              :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.priority="{ item }">
                        <v-avatar
                            :color="item.priority === 'Стандартный' ? 'green' : 'red'"
                            size="36"
                        >
                            <span class="white--text text-h5"></span>
                        </v-avatar>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="editMoving(item)" color="primary">
                                    {{$t('completed')}}
                                </v-btn>
                            </template>
                            <span>{{$t('completed')}}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="loading" z-index="10" color="primary">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>


import {mapGetters} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    name: "Moving",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    computed: {
        ...mapGetters(['lang']),
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    watch: {
        options: {
            handler() {
                this.getMoving()
            },
            deep: false
        }
    },
    data() {
        return {
            loading: false,
            movingItems: [],
            options: {},
            headers: [
                {
                    text: this.$t('priority'),
                    align: "center",
                    sortable: false,
                    value: "priority"
                },
                {
                    text: this.$t('container'),
                    align: "center",
                    sortable: false,
                    value: "container"
                },

                {
                    text: this.$t('where_from'),
                    align: "center",
                    sortable: false,
                    value: "where_from"
                },
                {
                    text: this.$t('where_to'),
                    align: "center",
                    sortable: false,
                    value: "where_to"
                },
                {
                    text: this.$t('completed'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
        }
    },
    methods: {
        async getMoving() {
            this.loading = true;
            this.$http
                .get('partner/moving')
                .then(res => {
                    this.movingItems = res.body.data
                })
                .catch(err => {
                    this.movingItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_moving'))
                })
                .finally(end => {
                    this.loading = false
                });
        },
        async editMoving(item) {
            if (confirm(this.$t('are_you_sure_you_want_moving'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .put(`partner/moving/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('moving_has_been_updated'))
                        this.getMoving()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('moving_not_has_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
